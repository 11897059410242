import { BBadge } from 'bootstrap-vue';
<template>
  <b-row>
    <b-col sm="6">
      <b-card title="รายการคืนยอดเสีย 🙌" sm="6">
        <b-card-text
          >ลูกค้าสามารถกดรับยอดเสีย 5% สูงสุด 300บาท รับได้ทุกวัน
          <br />
          <br />
          สามารถกดรับได้ทุกวัน หลังเวลา 00:15น
        </b-card-text>
        <b-form-group>
          <label>ยอดเครดิตที่สามารถรับได้</label>
          <b-form-input readonly v-model="cashback" />
        </b-form-group>

        <b-alert
          variant="warning"
          :show="cashbackStatus === 1 ? false : true"
          class="mt-1"
        >
          <div class="alert-body">
            <feather-icon icon="InfoIcon" class="mr-50" />
            เงื่อนไข: การรับเครดิตยอดเสียต้องทำ 1 เทิร์นก่อนถอนนะคะ
          </div>
        </b-alert>
        <b-button
          :disabled="cashbackStatus === 1 ? true : false"
          variant="primary"
          type="submit"
          block
          @click="showMsgBox('ยืนยันการรับยอดเสีย', 'cashback')"
        >
          รับเครดิต
        </b-button>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard,
  BCardText,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BAlert
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BAlert
  },
  data() {
    return {
      cashback: 0,
      commission: 0,
      cashbackStatus: 1,
      commissionStatus: 1,
      data: null,
      empty: false
    }
  },
  methods: {
    showMsgBox(title, type) {
      this.$bvModal
        .msgBoxConfirm(
          `เมื่อรับเครดิตเงินคืนแล้วจะต้องทำ 1 เทิร์น จึงจะสามารถถอนได้`,
          {
            title,
            size: 'sm',
            okVariant: 'primary',
            okTitle: 'รับทราบ',
            cancelTitle: 'ยกเลิก',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true
          }
        )
        .then(value => {
          console.log(value)
          if (value === true) {
            console.log(1)
            this.retieveCashback(type)
          }
        })
    },
    async retieveCashback(type) {
      const resp = await this.$http.post('member/retieveCashback', {
        id: this.data.id,
        type
      })
      if (resp.data.success) {
        await this.getCashback()
      }
    },
    async getCashback() {
      const resp = await this.$http.post('member/getCashback')
      if (resp.data.success) {
        console.log('A', resp.data.cashBack)
        if (resp.data.cashBack !== null) {
          this.data = resp.data.cashBack
          this.commission =
            resp.data.cashBack.com_status === 1
              ? 0
              : resp.data.cashBack.commission
          this.cashback =
            resp.data.cashBack.cash_status === 1
              ? 0
              : resp.data.cashBack.cashback
          this.cashbackStatus = resp.data.cashBack.cash_status
          this.commissionStatus = resp.data.cashBack.com_status
        } else {
          this.empty = true
        }
      }
    }
  },
  async beforeMount() {
    await this.getCashback()
  }
}
</script>

<style></style>
